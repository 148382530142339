import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../../components/elements/Navbar'
import { Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import presseImage1 from '@images/presse/brauhaus-darts-wm-24-preview-full.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const ContentRow = tw.div`grid grid-cols-12 gap-4 mt-4`
const ContentImageWrapper = tw.div`flex justify-center`
const ContentText = tw.div`col-span-12 xs:col-span-8`
const ImageSource = tw.small`text-white`
const ContentRowImage = tw.div`col-span-12`

const PressePage = () => {
  const presseImageDl1 = '/download/erdinger-brauhaus-wird-partner-der-pdc-darts-wm-2024-auf-sport1.jpg';

  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full">
                Game on! ERDINGER Brauhaus wird Partner der PDC Darts-WM 2024 auf SPORT1
              </Subtitle>
              <ContentText>
                <p>
                  Erding, Dezember 2023<br />
                  Wenn im Londoner „Ally Pally“ ab dem 15. Dezember wieder absolute Darts-
                  Ausnahmestimmung herrscht, tausende Fans in den kuriosesten Kostümen ihren Stars zujubeln und
                  Top-Favoriten wie „Bully Boy“, „MvG“ oder „Cool Hand Luke“ um den Titel kämpfen, dann ist
                  ERDINGER Brauhaus mittendrin. Denn ERDINGER Brauhaus ist offizieller Partner der PDC Darts-WM
                  2024 auf SPORT1.
                </p>
                <p>
                  Vom 15. Dezember 2023 bis zum 03. Januar 2024 geht es für 96 Sportler im legendären Alexandra
                  Palace um die Krone des Darts-Sports – wie üblich live zu verfolgen auf SPORT1, der führenden
                  deutschen Sportplattform und Heimat der Darts-Fans schlechthin. Neu dabei ist ERDINGER Brauhaus
                  mit einem aufmerksamkeitsstarken TV-Presenting als inoffiziellen Anwurf vor jeder Übertragung.
                  Erstrahlt der Einspieler, weiß jeder Zuschauer vor dem Bildschirm: Es geht los! Zusätzlich sind
                  umfassende Social-Media- und Online-Maßnahmen geplant. „Das Interesse an Darts wächst und
                  wächst und wächst – auch in Deutschland und gerade unter Jüngeren. Darts hat es geschafft, sich zu
                  einer Profi-Sportart zu entwickeln und dabei seine Wurzeln in der Pub-Kultur Englands zu bewahren.
                  Was uns an dem Sport fasziniert, ist die Leidenschaft, das Können und das große Spektakel, an dem
                  alle – Fans wie Profis – gemeinsam teilhaben. Hier passt ERDINGER Brauhaus natürlich perfekt“, so
                  Wolfgang Kuffner, Marketingleiter bei der Privatbrauerei ERDINGER Weißbräu und ergänzt: „Durch
                  unsere Partnerschaft mit SPORT1 zur Darts-WM 2024 werden wir die Bekanntheit von ERDINGER
                  Brauhaus deutlich steigern. Als Titelsponsor der BPong-Bundesliga haben wir uns ja bereits im
                  professionellen Beer Pong, einer weiteren jungen, aufstrebenden Präzisions-Trendsportart, etabliert –
                  gleiches erhoffen wir uns im Darts.“ Stefan Obstmayer, Chief Sales Officer (CSO) von SPORT1, ist
                  ebenfalls von der Kooperation begeistert: „Die Darts-WM ist jedes Jahr ein besonderes Highlight im
                  Sportkalender. Mit erstmals fünf deutschen Teilnehmern erwarten wir wieder Top-Reichweiten auf
                  unseren TV-, Digital- und Social-Media-Kanälen. Wir freuen uns gemeinsam mit ERDINGER Brauhaus
                  als Presenter, den Fans Spitzenunterhaltung live aus London zu bieten. In diesem Sinne wünschen wir
                  uns allen schon jetzt: Good Darts!“
                </p>
                <br />
              </ContentText>

              <ContentImageWrapper>
                <div>
                  <a 
                      href={presseImageDl1}
                      target="_blank"
                    >
                      <img src={presseImage1} alt="Game on! ERDINGER Brauhaus wird Partner der PDC Darts-WM 2024 auf SPORT1" />
                  </a>
                  <ImageSource>Bildrechte: ERDINGER Weißbräu</ImageSource>
                </div>
              </ContentImageWrapper>

            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
